<template>
  <el-menu class="custom-dashbaords-menu" :router="true">
    <div v-if="dashboards && dashboards.length">
      <el-menu-item
        v-for="(item, index) in dashboards"
        :key="index"
        :index="item.name"
        :class="{
          'is-active':
            `/utilities/custom_dashboards/${item.name}` === $route.fullPath
        }"
        :route="`/utilities/custom_dashboards/${item.name}`"
      >
        {{ item.name }}
      </el-menu-item>
    </div>
    <div v-else class="text-center pt-4 text-gray-600">
      <p>No custom dashboards configured</p>
    </div>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      dashboards: 'Config/getCustomDashboards'
    })
  },

  beforeMount() {
    // Redirect to first dashboard
    if (
      this.$route.fullPath === '/utilities/custom_dashboards' &&
      !!this.dashboards.length
    ) {
      this.$router.push(
        `/utilities/custom_dashboards/${this.dashboards[0].name}`
      )
    }
  }
}
</script>

<style scoped>
.custom-dashbaords-menu {
  height: 100%;
}

.custom-dashbaords-menu .el-menu-item.is-active {
  background-color: #eaeaea !important;
}
</style>
