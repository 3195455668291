<template>
  <th-page-wrapper color="var(--secondary-color)">
    <el-row class="custom-dashboards-wrap">
      <el-col :span="5">
        <custom-dashboards-nav />
      </el-col>
      <el-col :span="19" class="custom-dashboards-content">
        <router-view />
      </el-col>
    </el-row>
  </th-page-wrapper>
</template>

<script>
import CustomDashboardsNav from './components/nav'
export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.utilities.custom_dashboards')
    }
  },
  components: {
    CustomDashboardsNav
  }
}
</script>

<style scoped>
.custom-dashboards-wrap,
.custom-dashboards-wrap > * {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid lightgrey;
}

.custom-dashboards-content {
  padding: 1rem;
}
</style>
